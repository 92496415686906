import moment from 'moment'
import { ShareButton } from './components/ShareButton';

const headingStyle = {
  textWrap: 'nowrap',
  //below were pulled out when removing https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css
  marginTop: '10px',
  marginBottom: '10px',
  lineHeight: '1.1',
  fontWeight: '500',
  color: 'rgb(51, 51, 51)', //'#fff',
  fontFamily: '"SF Display", sans-serif',
  //https://stackoverflow.com/questions/32660748/how-to-use-apples-new-san-francisco-font-on-a-webpage
}

export const FormatDateShort = (dateString) => moment(dateString, "YYYY-MM-DD").format("MMM Do");

const HeaderText = ({ banner, meta, link}) => (
    <div>
      <h2 style={{...headingStyle, fontSize: '30px'}}>{banner}</h2>
      <h3 style={{...headingStyle, fontSize: '24px'}}>
      {meta + ' '}
        <ShareButton title="Let's Go" url={link}/>
      </h3>
    </div>
  );

export const headerRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  flexGrow: 1,
  flexShrink: 0,
  justifyContent: 'flex-start', //justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  rowGap: '12px',
  columnGap: '12px',

  maxHeight: '100px',
}

export const StaticHeader = ({ trip }) => (
    <div style={{ ...headerRowStyle }}>
    <img src={trip.img || trip.planDetails?.img} alt="" width="100" height="100"/>
    <HeaderText 
      banner={trip.planName} 
      meta={trip.planDetails?.meta || FormatDateShort(trip.planDetails?.start_date)} 
      link={trip.sheetId ? `/s/${trip.sheetId}` : trip.link}
    />
    </div>
);