import { useEffect, useState } from "react";
import { PlusButton } from './components/PlusButton';
import { ListButton } from './components/ListButton';
import { SegmentAdder } from './SegmentAdder';
import { PlanCard } from './PlanCard';
import { BlurBar } from './components/BlurBar';
import Card from './cardStack/Card';
import CardStack from './cardStack/CardStack';

export const HomePage = ({ stack = false }) => {
  const [showPlusModal, setShowPlusModal] = useState(false);
  const [status, setStatus] = useState('');
  const [plans, setPlans] = useState([]);
  const [selValue, setSelValue] = useState('all');
  const [selOptions, setSelOptions] = useState([]);
  const [selectedCardIdx, setSelectedCardIdx] = useState(-1);
  const [appTitle, setAppTitle] = useState('Plans');
  const [stackOptions, setStackOptions] = useState({
    'View Options': {
      "Full Header": { value: true } 
    },
    'Plan Types': {
      "list": { value: true },
      "search": { value: true },
      "res": { value: true },
    }
  });
  const colors1 = ['#c12014','#f16d5e','#4bb491','#1482d2','#b2b3b2','#27AE60','#649ae1','#f0a164','#d2d2d2']
  const colors2 = ['#b2b3b2','#ffffff','#d2d2d2']
  const colors = (stackOptions['View Options']['Full Header']['value']) ? colors1 : colors2
  //const backgroundColor = colors[(trip.planId) % colors.length] || 'white'
  console.log(stackOptions)

  function getParentKey(state, childKey) {
    for (const parentKey in state) {
      if (typeof state[parentKey] === 'object' && state[parentKey].value) {
        const childObject = state[parentKey].value;
        if (childKey in childObject) {
          return parentKey;
        }
      }
    }
    console.log('null for: ', state, childKey)
    return null; // Return null if not found
  }

  const toggleStackOption = (keys) => {
    const group = getParentKey(stackOptions, key)
    console.log(key, group)
    console.log(stackOptions[group]['value'][key])
    const groupOptionsNew = {
      [group]: {
        value: {
        ...stackOptions[group]['value'],
        [key]: {value: !stackOptions[group]['value'][key] }
      }
    }  
    }
    setStackOptions({...stackOptions, ...groupOptionsNew})
  } 
  
  const toggleStackOption2 = (key) => {
    const group = getParentKey(stackOptions, key)
    console.log(key, group)
    console.log(stackOptions[group]['value'][key])
    const groupOptionsNew = {
      [group]: {
        value: {
        ...stackOptions[group]['value'],
        [key]: {value: !stackOptions[group]['value'][key] }
      }
    }  
    }
    setStackOptions({...stackOptions, ...groupOptionsNew})
  }

  const setData = (data) => {
    if (plans.length==1) setSelectedCardIdx(0) //need X button when 1 plan, doesnt work on first load
    setPlans(data)

    const options = !stack
         ? data.map(p => p.planName)
         : ['all', { 'plan types': Array.from(new Set(data.map(p => p.planType))) }]
    setSelOptions(options)
    !stack && setSelValue(options[0])
  }

  const fetchPlans = ( method, payload ) => {
    
    const plansURL = '/plans' //'/testplans' //
    fetch(plansURL, {
      method: method, //GET, POST, PUT, DEL
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      ...(payload && {body: JSON.stringify(payload)})
    })
    .then(response => response.json())
    .then(resjson => resjson.plans.length > 0 && setData(resjson.plans.toReversed()))
    .then(()=>setStatus('success'))
    .then(()=>setShowPlusModal(false)) //block/show loading until plan is added
    .catch(()=>setStatus('error'));
  }

  useEffect(()=>{
     setStatus('loading');
     fetchPlans('GET')
     }, []);
  
  const addPlan = (payload) => {
    fetchPlans('POST', payload)
  }

  const updatePlan = (planId) => (planDetails) => {
    const updInputPlan = {...plans[planId], planDetails} //planDetails must keep same name/key not be pd
    const updPlans = plans
    updPlans[planId] = updInputPlan
    const payload = {planId, planDetails}
    fetchPlans('PUT', payload)
  };

  const deletePlan = (idx) => {
    const planId = plans[idx].planId
    if (confirm(`Permanently delete planId #${planId}?`)) {
      
      fetchPlans('DELETE', {planId})
      setSelectedCardIdx(-1)
    }
  };

  const togglePlansMenu = () => {
    /*const heightStr = prompt("Set the card's header height in pixels", '60')
    const height = parseInt(heightStr, 10)
    if (height) {
      //setCardHeaderHeight(height)
      setStackOptions({...stackOptions, 'Full Header': !stackOptions['Full Header']})
    }*/

    const inputPrompt = prompt("..... Who goes there?", 'G')
    if (inputPrompt) {
      setAppTitle(inputPrompt)
    }
  }

  const toolbarStyle = {
    overscrollBehavior: 'contain', //https://dev.to/mpuckett/the-holy-grail-web-app-shell-with-header-and-footer-for-iphone-549j
    //overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff', 
    padding: '2.25vh 0vw',//'4.5vw 0vw', //slightly less padding than native passbook
    fontSize: '4.5vh', 
    maxHeight: '4.5vh',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif',
    //https://stackoverflow.com/questions/32660748/how-to-use-apples-new-san-francisco-font-on-a-webpage
  }

  //const plansFiltered = plans.filter((p) => selValue == 'all' || selValue == p.planType || selValue == p.planName)

  const planTypes = stackOptions['Plan Types']
  const activePlanTypes = Object.keys(planTypes).filter(key => planTypes[key]['value'] === true);
  const plansFiltered = plans.filter((p) => activePlanTypes.includes(p.planType))

  // bodyHeight = totalHeight - toolbarHeight - blurbarHeight (if it's visible)
  const totalHeightInPixels = window.innerHeight;
  const toolbarHeight = totalHeightInPixels * (.045 + .045)
  const blurbarHeight = totalHeightInPixels * .045 * (window.navigator.standalone | 0)
  const bodyHeightInPixels = Math.floor(totalHeightInPixels - (toolbarHeight + blurbarHeight));

  return (
    <div style={{padding: '0vw 4.5vw'}}>
       <div style={toolbarStyle}>
        {status !== 'error'
                ? <b onClick={togglePlansMenu}>{appTitle}</b>
                : <b>No Plans Found</b>
        }
        <div style={{display: 'flex'}}>
        {selectedCardIdx < 0 
        ? <ListButton diameter='4.5vh' selOptions={stackOptions} changeHandler={setStackOptions}//{toggleStackOption}  
          //(val) => setSelValue(val)}
          />
        : <PlusButton diameter='4.5vh' status={'error'} onClick={() => deletePlan(selectedCardIdx)}/>
        }
          <div style={{width: '2.25vh'}}></div>
          <SegmentAdder diameter='4.5vh' submitHandler={addPlan}/>
        {/* {showPlusModal && <SegmentAdder type={selValue} submitHandler={addPlan} setShowPlusModal={setShowPlusModal}/>} */}
       </div>
       </div>

       {
        status === 'success' && plansFiltered.length === 0 && (
           <div style={toolbarStyle}>
             Hit the plus button to add a plan!
           </div>
        )
       }
       {
        status === 'success' && plans.length > 0 && (
           (!stack || plansFiltered.length === 1)
             ? <PlanCard trip={plansFiltered[0]} height={bodyHeightInPixels} planUpdater={updatePlan}/>
             : <CardStack 
                 height={bodyHeightInPixels} footerHeight={blurbarHeight} 
                 headerHeight={stackOptions['View Options']['Full Header']['value'] ? 112 : 60}
                 selectedIdx={selectedCardIdx} selectedUpdater={setSelectedCardIdx}
               >
                 {plansFiltered.map((plan, index) => (
                   <Card key={index}>
                     <PlanCard trip={{...plan, bgColor: colors[(plan.planId) % colors.length]}} height={bodyHeightInPixels} 
                     //planUpdater={updatePlan}
                       {...(selectedCardIdx >= 0 ? {planUpdater: updatePlan} : {})}
                     />
                   </Card>
                 ))}
               </CardStack>
        )
       }

       {window.navigator.standalone && <BlurBar/>}
     </div>
     );
}