import React, { useEffect, useState } from "react";
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import * as Segments from './Segments'

export const fetchSearch = ( url, statusSetter, resultsSetter ) => {

  statusSetter('Loading');
  fetch(url)
    .then((response) => {
        return response.json()
          .then((json) => {
            if (response.ok) {
              return Promise.resolve(json)
            }
            return Promise.reject(json)
          })
      })
    .then(resultsSetter)
    .then(()=>statusSetter('Success'))
    .catch((ex) => {
     console.log(`Exception fetching: ${url}`, ex)
     statusSetter(ex.error || ex.message)
    });
}

export const ItineraryList = ( { items } ) => {

  const styles = {
    //background: 'white', //remove for card look
    minHeight: '280px',
    width: '100%', // otherwise width is based on biggest inner element (nested link!)
    //margin: '1px auto',//overflow on right for PR... auto bad?
    overflowY: 'auto',
    overflowX: 'hidden',
    lineHeight: '30px'
  }

  function pojoSwitch(item) {
    const Segment = Segments.segmentTypes[item.type];
    return <Segment item={item}/>;
  }

  return (
    <div style={styles}>
    <Itinerary>
      {
        items && items.map( (item, index) =>

          <div key={index}>
          {pojoSwitch(item)}
          </div>
          )
      }
    </Itinerary>
    </div>
  );
}
export default ItineraryList;

export const ItineraryBody = ( {trip, inputsUpdater} ) => {
  const searchParams = new URLSearchParams();
  searchParams.append("trip_id",  trip?.planDetails?.sheetId);

  const url =  '/sheetstrip?' + searchParams.toString();

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);
  useEffect(()=>{
    if ( trip?.segments?.length > 0 ) {
      setList({'trip': trip})
      return setStatus('Success')
    }
    if (!trip?.planDetails?.sheetId) {
      return setStatus('Link provided is not valid')
    }
    
    fetchSearch(url, setStatus, setList)

  }, [url]);

  return (
    <>
    {list.length == 0 
      ? <div style={{textAlign: 'center'}}><b>{status}</b></div>
      :<ItineraryList items={list.trip.segments}/>
    }
    </>
  );
}