export const ImgButton = ( props ) => {
  var buttonStyles = {
    width: props.diameter,
    height: props.diameter,
    borderRadius: '100%',
    backgroundColor: '#fff',
    backgroundImage: `url(${props.imgURL})`,
    backgroundPosition: '50% 50%',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <div 
      style={buttonStyles}
      {...(props.status ? {className: props.status} : {})}
      {...(props.onClick ? {onClick: props.onClick} : {})}
    >
      {props.children}
    </div>
  );
}

export const GroupSelect = ( props ) => {
  const selectStyles = {
    width: props.diameter,
    height: props.diameter,
    position: 'relative',
    top: '-10px',
    right: '0px',
    outline: 'none',
    border: 'transparent',
    color: 'transparent',
    backgroundColor: 'transparent'
  }

  let optionIndex = 0;

  function mapOptionsToGroups(e) {
    const selectElement = e.target;
    
    const selectedOptions = Array.from(selectElement.selectedOptions);
    
    // Get all options in the select element
    const allOptions = Array.from(selectElement.options);
  
    const groupsMap = {};
  
    allOptions.forEach(option => {
      let group = null;
      
      // Find the optgroup parent
      while (option.parentNode && option.parentNode.tagName !== 'OPTGROUP') {
        option = option.parentNode;
      }
      
      if (option.parentNode) {
        group = option.parentNode;
      }
  
      // Get the optgroup label
      const groupLabel = group ? group.label : null;
  
      if (!groupsMap[groupLabel]) {
        groupsMap[groupLabel] = {};
      }
  
      // Add all options to the group object
      groupsMap[groupLabel][option.value] = {value: option.selected}//option.selected;
    });
  return groupsMap
  }

  return (
    <select
      style={selectStyles} 
      name="options" 
      onChange={(event) => {
        
        //props.changeHandler([...event.target.selectedOptions].map(o => o.value))
        props.changeHandler(mapOptionsToGroups(event))
        
        
        //props.changeHandler(event.currentTarget.value)
      }}
      defaultValue={['What type of plan?','Full Header', 'list', 'search', 'res']}
      {...(props.multi ? {multiple: true} : {})} //headerHeight, hide past, dark mode
    >
      {/* <option selected disabled hidden>Choose an Option</option> */}
      {Object.keys(props.selOptions).map((option, index) => {
        const groupOptions = props.selOptions[option]
        console.log(option, props.selOptions[option])
      if (typeof groupOptions === 'object') {
        return (
        <optgroup key={index} label={option}>
          {Object.keys(groupOptions).map((item) => {
            const optionKey = optionIndex++
            return (
             <option key={optionKey} value={item}>
                {item}
             </option>
            );
          })}
        </optgroup>
      )
      } else {
        console.log(groupOptions)
        const optionKey = optionIndex++
        return (
          <option key={optionKey} value={option.label}>
            {option.label}
          </option>
        )
      }

        /*if (typeof option === 'object') {
          // option groups are represented by an object w/ one key for the group's label
          const groupKey = Object.keys(option)[0];
          const groupOptions = option[groupKey];
          return (
            <optgroup key={index} label={groupKey}>
              {groupOptions.map((item) => {
                const optionKey = optionIndex++
                return (
                 <option key={optionKey} value={item}>
                    {item}
                 </option>
                );
              })}
            </optgroup>
          );
        } else {
          const optionKey = optionIndex++
          return (
            <option key={optionKey} value={option}>
              {option}
            </option>
          );
        }*/
      })}
    </select>
  );
}

export const ListButton = ( props ) => (
  <ImgButton diameter={props.diameter} imgURL={process.env.PUBLIC_URL + '/list.svg'}>
    <GroupSelect multi={true} diameter={props.diameter} selOptions={props.selOptions} changeHandler={props.changeHandler}/>
  </ImgButton>
)

export const AddButton = ( props ) => (
  <ImgButton diameter={props.diameter} imgURL={process.env.PUBLIC_URL + '/plus.svg'}>
    <GroupSelect diameter={props.diameter} selOptions={props.selOptions} changeHandler={props.changeHandler}/>
  </ImgButton>
)