import React from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from './PlanCard';

export const PlanPage = ({type = "input"}) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const planDetails = Object.fromEntries(searchParams.entries())
  const inputPlan = {planType: type, planDetails}

  const setParams = (details) => {
    const { addlDetails, ...params } = details;
    setSearchParams(params)
  }

  const cardStyle = {
    background: 'RGB(242,242,247)', //'RGB(178,179,178)',//'RGB(179,201,253)',
    height: '100svh',
  }

  return (
    <div>
       {<Card trip={inputPlan} cardStyle={cardStyle} planUpdater={setParams}/>}
    </div>
  );
}