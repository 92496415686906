import { useState } from 'react';
import { useForm } from "react-hook-form";
import { createSearchParams } from "react-router-dom";
import moment from 'moment'
import Modal, {ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import Button from "@kiwicom/orbit-components/lib/Button";
import InputField from "@kiwicom/orbit-components/lib/InputField";
//import InputGroup from "@kiwicom/orbit-components/lib/InputGroup";
//import * as Icons from "@kiwicom/orbit-components/icons";
import { AddButton } from './components/ListButton';
//import {segmentTypes} from './Segments'
import {ListAdder} from './ListAdder'
import {ResAdder} from './ResAdder'
import {FlightSearchAdder} from './FlightSearchAdder';
//import {EventAdder} from './EventAdder'

const hydrateSearchPlan = ( data ) => {
  const name = `${data.cityCodeFrom} → ${data.cityCodeTo}`
  const link = '/search?' + createSearchParams(data).toString() 
  const img = process.env.PUBLIC_URL + '/plane.png'
  return {link, name, img, ...data}
}

const hydrateResPlan = ( data ) => {
  const meta = moment(data.res_date, "YYYY-MM-DD").format("ddd, MMM Do");
  const link = '/res?' + createSearchParams(data).toString() 
  const img = 'https://i.giphy.com/TJssvTF16urPfizSVy.webp'
  return {link, meta, img, ...data}
}

export const SegmentAdder = ( {submitHandler, diameter} ) => {
  const [showPlusModal, setShowPlusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setPlanType] = useState('search'); //hardcoding default for now

  const { register, watch, control, handleSubmit, setValue } = useForm(); //unitialize?
  const submitPayload = (data) => {
    setIsLoading(true)
    if (type === 'search') data = hydrateSearchPlan(data)
    if (type === 'res') data = hydrateResPlan(data)

    const {link, name, ...details} = data;
    if (link === null || name === null) throw new Error('Link and Name must not be null');

    const payload = {
      type, link, name, details
    }
    submitHandler(payload)
    setShowPlusModal(false)
  }

  const adderForms = {
    'list': <ListAdder register={register} watch={watch} setValue={setValue}/>,
    'search': <FlightSearchAdder register={register} control={control} setValue={setValue}/>,
    'res': <ResAdder register={register} watch={watch} setValue={setValue}/>,
    //'event': <EventAdder register={register} watch={watch}/>,
    //<ToDoInput register={register} setValue={setValue} link={linkWatcher}/>
  }  

  const options = 
  //['What type of plan?', { 'plan types': 
  { 'What type of plan?': 
    //Object.keys(adderForms)
    adderForms
    //Array.from(new Set(data.map(p => p.planType))) 
  }
  console.log(options)

  const t = {
    'View Options': {
      "Full Header": { value: true } 
    },
    'Plan Types': {
      "list": { value: true },
      "search": { value: true },
      "res": { value: true },
    }
  }

  return (
    <>
    <AddButton 
      diameter={diameter}
      selOptions={options}//Object.keys(adderForms).map(s => ({ label: s, value: s}))} 
      changeHandler={(val) => {
            setPlanType('res') //val
            setShowPlusModal(true)
          }}
    />
    {showPlusModal && 
    <Modal
      onClose={(e) => {
        setShowPlusModal(false);
        e.stopPropagation();
      }}
    >
      <form noValidate onSubmit={handleSubmit(submitPayload) }>
      <ModalHeader
        title='Add a Plan'
        //description='Hint: form will auto-fill if you have a link copied to your clipboard'
        //description='Start by selecting a plan type or try auto-filling from your clipboard!'
      />
      <ModalSection>

      { adderForms[type] }

      </ModalSection>
      <ModalFooter>
        <Button 
          fullWidth={true} 
          loading={isLoading} 
          submit={true}
        >
        Submit
        </Button>
      </ModalFooter>
      </form>
    </Modal>
    }
    </>
  );
};
